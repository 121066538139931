import {Link} from "react-router-dom";
import React from "react";
import { HashLink } from 'react-router-hash-link';

export default function () {
    return (
        <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top wt-border" style={{zIndex: 10000}}>
            <div className="container">
                <HashLink className="navbar-brand" to="/#">explis.</HashLink>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto navbar-right">
                        <li className="nav-item"><HashLink className="nav-link js-scroll-trigger" to="/#">Über uns</HashLink></li>
                        <li className="nav-item"><HashLink className="nav-link js-scroll-trigger" to="/#consulting">Consulting</HashLink></li>
                        <li className="nav-item"><HashLink className="nav-link js-scroll-trigger" to="/#contact">Kontakt</HashLink></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}