import React, { useState } from 'react';
import cloud_logos from "./cloud_logos.png"
import serverless_logos from "./serverless_logos.png"
import cicd from "./cicd.png"
import quality from "./kissclipart-portfolio-management-icon-clipart-project-manageme-54473c6b08102d3c.png"
import contactEmail from "./email_kontaktanfrage.png"

export default function () {
    return (
        <div>
            <div id="explis" className="main">
                <div className="hero">
                    <div className="container">
                        <div className="hero-inner">
                            <div className="hero-content pt-4">
                                <h2>Native Cloud-Lösungen und Cloud-Migration</h2>
                                <p>Explis bietet ein hochspezialisiertes Team von Softwarearchitekten, Entwicklern und Cloud-Experten.
                                    Mit unserer langjährigen Erfahrung helfen wir jedes Projekt erfolgreich umzusetzen.</p>
                                <a className="btn-action" href="#consulting">Mehr Infos</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="consulting" className="flex-split">
                <div className="container-m">
                    <div className="flex-intro align-center wow fadeIn">
                        <h2>Beratung durch explis</h2>
                        <p>Egal ob Greenfield Projekt, Brownfield Projekt oder die Migration von On-Premise Diensten in die Cloud, wir bieten kundenspezifische Lösungen.</p>
                    </div>


                    <div className="flex-inner flex-inverted align-center my-5">
                        <div className="f-text">
                            <div className="left-content">
                                <h2 className='mt-5'>Cloud-Migration</h2>
                                <p>Wir bieten verlässliche Portierung von On-Premise Services in die AWS, Azure oder Google Cloud - inklusive Autoscaling, Monitoring und
                                    DevOps.</p>
                            </div>
                        </div>
                        <div className="f-image f-image-inverted">
                            <img className="img-fluid mx-md-5" src={cloud_logos} alt="AWS"/>
                        </div>
                    </div>

                    <div className="flex-inner align-center flex-md-row-reverse my-5">
                        <div className="f-text">
                            <div className="left-content">
                                <h2 className='mt-5'>Native Cloud-Lösungen</h2>
                                <p>Native Cloud-Lösungen nutzen die Möglichkeiten der Cloud optimal aus.
                                    Wir bieten Beratung oder Eigenentwicklung für FaaS, Container-based Serverless,
                                    Message-Based Reactive Systems und klassische Computing-Ansätze.</p>
                            </div>
                        </div>
                        <div className="f-image wow">
                            <img className="img-fluid mx-md-5" src={serverless_logos} alt="Serverless"/>
                        </div>
                    </div>

                    <div className="flex-inner flex-inverted align-center my-5">
                        <div className="f-text">
                            <div className="left-content">
                                <h2 className='mt-5'>CI/CD</h2>
                                <p>Ein verlässlicher CI/CD-Prozess ist unerlässlich für Qualitätsmanagement.
                                    Wir entwickeln Prozesse und Systeme in jeder Cloud und für alle gängigen On-Premise Lösungen.</p>
                            </div>
                        </div>
                        <div className="f-image f-image-inverted">
                            <img className="img-fluid mx-md-2" src={cicd} alt="AWS"/>
                        </div>
                    </div>

                    <div className="flex-inner align-center flex-md-row-reverse my-5">
                        <div className="f-text">
                            <div className="left-content">
                                <h2 className='mt-5'>Qualitätsmanagement</h2>
                                <p>Explis bietet Beratung in den Bereichen Softwarearchitektur, Testing, Monitoring und Security.</p>
                            </div>
                        </div>
                        <div className="f-image wow my-auto align-items-center row mx-0">
                            <div className="mx-auto">                            
                                <img className="img-fluid mx-md-2" src={quality} alt="QualityManagementIcon" style={{width: '95px'}}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div id="contact" className="yd_cta_box" style={{height: '90vh'}}>
                <div className="container h-100">
                    <div className="cta_box h-100">
                        <div className="cta_box_inner row align-items-center h-100">
                            <div className="col-sm-12 col-md-12">
                                <h4>Kontakt</h4>
                                <h2>Haben Sie Interesse oder benötigen Sie mehr Informationen?
                                    Gerne können Sie uns persönlich anschreiben unter <img className="pt-1 pt-md-0" src={contactEmail} alt={"kontakt"}/>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}