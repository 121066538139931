import React from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import ImpressumPage from "./pages/impressum/ImpressumPage";
import PrivacyNoticePage from "./pages/privacynotice/PrivacyNoticePage";
import AppNavigation from "./components/nav/AppNavigation";
import LandingPage from "./pages/landingpage/LandingPage";
import { HashLink } from 'react-router-hash-link';

class App extends React.Component<any, any> {
    render() {
        return (
            <React.StrictMode>
                <BrowserRouter>
                    <AppNavigation />
                    
                    <div id='main' className='main'>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/impressum" element={<ImpressumPage />} />
                            <Route path="/data_privacy" element={<PrivacyNoticePage />} />
                        </Routes>
                        <div className="footer">
                            <div className="container">
                                <div className="row text-center">
                                    <div className="col-lg-2 col-md-3 col-sm-12">
                                        <div className="footer-logo">
                                            <h2 style={{textTransform: 'none'}}>explis.</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-sm-12">
                                        <ul className="footer-menu">
                                            <li><HashLink className="#main" to="/#">Über uns</HashLink></li>
                                            <li ><HashLink className="#main" to="/#consulting">Consulting</HashLink></li>
                                            <li ><HashLink className="#main" to="/#contact">Kontakt</HashLink></li>
                                            <li ><Link className="#main" to="/impressum">Impressum</Link></li>
                                            <li ><Link className="#main" to="/data_privacy">Datenschutz</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-3 col-md-2 col-sm-12">
                                        <div className="footer-links"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </BrowserRouter>
            </React.StrictMode>
        )
    }
}

export default App;
