import React from 'react';

import contact from "./email_impressum.png"

export default function () {
    return (
        <div style={{paddingTop: 60, minHeight: '90vh'}}>
            <div className='container'>
                <div className="flex-inner flex-inverted align-center">
                    <div className="f-text">
                        <div className="left-content">
                            <h2>Impressum</h2>
                            <br/><br/>
                            <h3>Angaben gemäß § 5 DDG</h3>
                            <p>
                                explis GmbH<br/>
                                c/o Combinat 56 <br/>
                                Adams-Lehmann-Straße 56<br/>
                                80797 München<br/>
                            </p>
                            <p>
                                Registereintragung:<br/>
                                Handelsregister: HRB 271879<br/>
                                Registergericht: München<br/>
                            </p>
                            <p>
                                Vertreten durch:<br/>
                                Richard Hauswald, Alexander Castendyck, Marco Eggersmann<br/>
                            </p>

                            <p>
                                Kontakt<br/>
                                <img src={contact} alt="contact details" width="250px" style={{marginLeft: "-5px"}}/>
                            </p>

                            <br/><br/>
                            <h3>Umsatzsteuer-Identifikationsnummer gemäß § 27a UStG:</h3>
                            <p>
                                DE350255988
                            </p>

                            <br/><br/>
                            <br/><br/>
                            <h3>EU-Streitschlichtung</h3>
                            <p>
                                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br/>
                                https://ec.europa.eu/consumers/odr/.<br/>
                                Unsere E-Mail-Adresse finden Sie oben im Impressum.<br/>
                            </p>
                        </div>
                    </div>
                    <div className="f-image f-image-inverted">

                    </div>
                </div>
            </div>
        </div>
    )
}